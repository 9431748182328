/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { clean, validate } from 'rut.js';
import { Observable } from 'rxjs';
import { GuiService } from './gui.service';

@Injectable({
  providedIn: 'root'
})
export class UtilProviderService {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private guiSV: GuiService) { }

  /**
    * Navigate to a Specific Path within the project
    *
    * @param path Path to which you want to Navigate
    * @returns a boolean promise true when the navigation is completed successfully.
    */
  public navigateToPath(path: string): Promise<boolean> {
    if (path === '') {
      path = '/404';
    }
    return this.router.navigate([path]);
  }

  /**
    * Save an Object with an Identification Key in LocalStorage
    * @param key identifier key of the object to save
    * @param data object to save
    *
    * It should be noted that the information stored in LocalStorage
    * It is persistent until the browser cache is cleared,
    * ie, STAYS when you close the browser. ~5MB
    */
  public setToLocalStorage(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
    * Gets an Object with an Identification Key from LocalStorage
    * @param key identifier key of the object to retrieve
    *
    * It should be noted that the information stored in LocalStorage
    * It is persistent until the browser cache is cleared,
    * ie, STAYS when you close the browser. ~5MB
    */
  public getFromLocalStorage(key: string): any {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    const value = JSON.parse(item);
    return value || null;
  }

  /**
    * Delete an Object with an Identification Key in LocalStorage
    * @param key identifier key of the object to Delete
    *
    * It should be noted that the information stored in LocalStorage
    * It is persistent until the browser cache is cleared,
    * ie, STAYS when you close the browser. ~5MB
    */
  public removeFromLocalStorage(key: string): void {
    const value = localStorage.getItem(key);
    if (value) {
      localStorage.removeItem(key);
    }
  }

  /**
    * Save an Object with an Identification Key in SessionStorage
    * @param key identifier key of the object to save
    * @param data object to save
    *
    * It should be noted that the information stored in SessionStorage
    * It is NOT persistent when closing the browser tab,
    * that is, it is Deleted when the current tab is closed. ~5MB
    */
  public setToSessionStorage(key: string, data: any): void {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  /**
    * Gets an Object with an Identification Key from SessionStorage
    * @param key identifier key of the object to retrieve
    *
    * It should be noted that the information stored in SessionStorage
    * It is NOT persistent when closing the browser tab,
    * that is, it is Deleted when the current tab is closed. ~5MB
    */
  public getFromSessionStorage(key: string): any {
    const item = sessionStorage.getItem(key);
    if (!item) {
      return null;
    }
    const value = JSON.parse(item);
    return value || null;
  }

  /**
    * Delete an Object with an Identification Key in the SessionStorage
    * @param key identifier key of the object to Delete
    *
    * It should be noted that the information stored in SessionStorage
    * It is NOT persistent when closing the browser tab,
    * that is, it is Deleted when the current tab is closed. ~5MB
    */
  public removeFromSessionStorage(key: string): void {
    const value = sessionStorage.getItem(key);
    if (value) {
      sessionStorage.removeItem(key);
    }
  }

  /**
    * Stores an Object with an Identification Key in the Cookies
    * @param key identifier key of the object to Save
    * @param data object to save
    * @param expires Number of days until the Cookie expires, or failing that a Date
    * @param path Route or path of the Cookie
    * @param domain Cookie domain
    * @param secure Boolean security indicator (is it secure ?)
    * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
    *
    * ~4Kb
    */
  setToCookies(
    key: string,
    data: any,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    this.cookieService.set(
      key,
      data,
      expires ? expires : undefined,
      path ? path : undefined,
      domain ? domain : undefined,
      secure ? secure : undefined,
      sameSite ? sameSite : undefined
    );
  }

  /**
    * Gets an Object with an Identification Key from Cookies
    * @param key identifier key of the object to Retrieve
    *
    * ~4Kb
    */
  getFromCookies(key: string): string {
    return this.cookieService.get(key);
  }

  /**
    * Check the existence of the Cookie with an Identification Key (Boolean)
    * @param key identifier key of the object to Retrieve
    *
    * ~4Kb
    */
  checkFromCookies(key: string): boolean {
    return this.cookieService.check(key);
  }

  /**
    * Delete an Object with an Identification Key in the SessionStorage
    * @param key identifier key of the object to Delete
    * @param path Route or path of the Cookie
    * @param domain Cookie domain
    * @param secure Boolean security indicator (is it secure ?)
    * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
    *
    * ~4Kb
    */
  removeFromCookies(
    key: string,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'None' | 'Strict'
  ): void {
    this.cookieService.delete(
      key,
      path ? path : undefined,
      domain ? domain : undefined,
      secure ? secure : undefined,
      sameSite ? sameSite : undefined
    );
  }

  /**
    * Transforms an observable to a promise.
    * @param OBS Observable with the response to get.
    * @returns a promise of type any with the response of an observable object.
    * It's basically a toPromise(), but it gives us more flexibility in handling the conversion.
    */
  public toPromise(OBS: Observable<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      if (OBS !== undefined && OBS !== null) {
        OBS.subscribe({
          next: (v) => resolve(v),
          error: (e) => reject(e)
        });
      } else {
        reject(null);
      }
    });
  }

  /**
    * Random numeric value generator, within a range.
    *
    * @param min Minimum number (default = 10,000)
    * @param max Maximum number (default = 100,000)
    * @returns a random integer between min (inclusive) and max (excluding)
    */
  public getRandomInt(min = 10000, max = 100000): number {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  /**
    * Delays the execution of the code in seconds (like a pause <<Pause>> or a sleep <<Sleep>> )
    *
    * @param seconds Time (in Seconds) for Delay
    * @returns an any promise true when the time has elapsed.
    */
  public delay(seconds: number): Promise<any> {
    const ms: number = seconds * 1000;
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
    * Validate that the email is corporate
    *
    * @param email Email to validate
    * @returns a true boolean value when the mail is corporate.
    */
  public validateCorporateEmail(email: string): boolean {
    const corporatePattern = /@planvital.cl\s*$/;
    if (email !== null && email !== undefined) {
      if (corporatePattern.test(email)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Generate random int
   *
   * @param min
   * @param max
   * @returns random int - min & max inclusive
   */
  public genrateRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public getFullName(name: string, lastName: string, secondSurname?: string): string {
    name = name.trim();
    lastName = lastName.trim();
    secondSurname = secondSurname ? secondSurname.trim() : '';
    let fullName = `${name} ${lastName}`.trim();
    fullName += ` ${secondSurname}`;
    return fullName.trim();
  }

  public getCurrentDate() {
    const pipe = new DatePipe('es-CL');
    const newDate = new Date();
    const currentDate = {
      menuDate: pipe.transform(newDate, 'MMM dd'),
      inputDate: pipe.transform(newDate, 'dd-MM-YYYY')
    };
    return currentDate;
  }

  public getFormattedDate(date: Date, dateFormat = 'yyyy-MM-dd'): string {
    return formatDate(date, dateFormat, 'es-CL');
  }

  public getRutAsNumber(rut: string): number {
    return Number(rut.slice(0, -1));
  }

  public stringReplaceAll(str: string, find: string, replace: string): string {
    return str.replace(
      new RegExp(find?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'),
      replace
    );
  }

  /**
   * Returns a single AlphaNumeric string, with no special characters.
   * @param str string that you input for replacement.
   * @returns string "clean", with the characters already replaced.
   */
  public stringReplaceClean(str: string): string {
    return str.replace(/[^a-zA-Z0-9 ]/g, "");
  }

  public getLink(route: string | undefined): string {
    if (route) {
      return `/${route}`;
    }
    return '';
  }

  public rutValidator(rutStr: string): boolean {
    return validate(clean(rutStr));
  }

  public getCollaboratorRoleByID(id: number) {
    let role: string;
    switch (id) {
      case 1: { // 1 - Administrador
        role = 'Administrador';
        break;
      }
      case 2: { // 2 - Supervisor de fidelizacion
        role = 'Supervisor';
        break;
      }
      case 3: { // 3 - Supervisor de ventas
        role = 'Supervisor';
        break;
      }
      case 4: { // 4 - Ejecutivo de fidelizacion
        role = 'Ejecutivo';
        break;
      }
      case 5: { // 5 - Ejecutivo de ventas
        role = 'Ejecutivo';
        break;
      }
      case 6: { // 6 - Operador
        role = 'Operador';
        break;
      }
      default: {
        role = 'Colaborador';
        break;
      }
    }
    return role;
  }

  public onDownloadXLSX(fileName: string, response: any) {
    const objectUrl: string = URL.createObjectURL(response);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

}
