import { Component } from '@angular/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  constructor(public font: FontService) { }

  public toggleFilter(): void {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      bodyElement.classList.toggle('high-contrast');
    }
  }

}
