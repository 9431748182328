import { Injectable } from '@angular/core';

type FontType = 'size-xsmall' | 'size-small' | 'size-medium' | 'size-large' | 'size-xlarge';

@Injectable({
  providedIn: 'root'
})
export class FontService {
  private currentIndex = 2;
  private sizes: Array<FontType> = ['size-xsmall', 'size-small', 'size-medium', 'size-large', 'size-xlarge'];
  public get size(): FontType { return this.sizes[this.currentIndex]; }

  public setSize(changeType: 'bigger' | 'smaller'): void {
    const nextIndex = changeType === 'bigger' ? this.currentIndex + 1 : this.currentIndex - 1;
    this.currentIndex = nextIndex >= this.sizes.length || nextIndex < 0 ? this.currentIndex : nextIndex;
  }
}
