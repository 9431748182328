import { Component, OnInit } from '@angular/core';
import { GuiService } from '@providers/util/gui.service';
import { environment } from 'environments/environment';
import { LoadingProviderService } from './shared/providers/loading/loading.provider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public showLoading = false;

  constructor(
    private loadingProvider: LoadingProviderService,
    private guiSV: GuiService
  ) {
    // if (!environment.production) {
    console.warn('Environment: ', environment.name);
    this.toast();
    // }
  }

  public ngOnInit(): void {
    this.loadingProvider.getLoadingRequestIntercepted().subscribe((showLoading) => setTimeout(() => this.showLoading = showLoading, 0));
  }

  private async toast() {
    await this.guiSV.stackedToast(`Environment: ${environment.name}`, 'success', 'bottom', 'alert-circle-outline');
  }
}
