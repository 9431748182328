<mat-dialog-actions align="end">
  <div mat-button mat-dialog-close class="close-modal">X</div>
</mat-dialog-actions>
<div mat-dialog-title>
  <span class="title-text">{{data.titleText}}</span>&nbsp;
  <span class="title-value">{{data.titleValue}}</span><br>
  <span class="title-status">Estado:</span>&nbsp;
  <span class="status-value">{{statusExecutive}}</span>
</div>

<mat-dialog-content class="mat-typography">
  <ion-grid class="tablefield-container">
    <ion-grid>
      <ion-row>
        <ion-col class="subtitle">
          Listado de clientes asignados
        </ion-col>
        <ion-col class="btns-row">
          <button class="btn-delete-loading" *ngIf="isLoadingDownload" disabled>Descargando...</button>
          <button class="btn-export" (click)="onDownload()" [disabled]="recordsTotal===0"
            *ngIf="!isLoadingDownload">Exportar Tabla</button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="people-filters">
      <form [formGroup]="filtersForm" (ngSubmit)="filterTable(filtersForm)">
        <ion-row class="first-row">
          <div size="auto" class="text-filter-container">
            <ion-input formControlName="nameRut" type="text" class="name-rut" placeholder="Buscar cliente"></ion-input>
          </div>
          <div class="element-container">
            <label for="antiquity">Antiguedad: </label><br>
            <ion-input formControlName="antiquity" type="number" class="fieldAntiquity"
              placeholder="N° de meses"></ion-input>
          </div>
          <div class="element-container">
            <label for="rimFrom">Segmento R.I.M: </label><br>
            <ion-input formControlName="rimFrom" type="number" class="fieldSegmento" placeholder="Desde"></ion-input>
            <ion-input formControlName="rimTo" type="number" class="fieldSegmento" placeholder="Hasta"></ion-input>
          </div>
          <div class="element-container">
            <select formControlName="voluntaryProduct" name="voluntary-product" id="voluntary-product">
              <option value="">Productos Voluntarios</option>
              <option *ngFor="let product of voluntaryProducts" value="{{product.id}}">{{product.name}}</option>
            </select>
          </div>
          <ion-col size="auto" class="btn-container d-center">
            <button type="submit" class="filter-btn">
              Filtrar
            </button>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>

    <div class="datatable-container">
      <table #dtt datatable id="peopleTableSupervisors" [dtOptions]="dtOptions" class="row-border hover"
        style="width:100%" aria-hidden="true"></table>
    </div>
  </ion-grid>

</mat-dialog-content>
