import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeCl from '@angular/common/locales/es-CL';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpInterceptorService } from '@interceptors/http-interceptor.service';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { DataTablesModule } from "angular-datatables";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalModule } from './global/global.module';
import { MaterialModule } from './material.module';
import { ComponentsModule } from './shared/components/components.module';
import { PipesModule } from './shared/pipes/pipes.module';

registerLocaleData(localeCl, 'es-CL');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    IonicModule.forRoot({
      rippleEffect: false,
      animated: false,
      mode: 'md'
    }),
    AppRoutingModule,
    HttpClientModule,
    PipesModule,
    GlobalModule,
    ComponentsModule,
    MaterialModule,
    DataTablesModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
  ],
  exports: [
    PipesModule,
    GlobalModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
