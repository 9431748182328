<mat-dialog-actions align="end">
  <div mat-button mat-dialog-close class="close-modal">X</div>
</mat-dialog-actions>

<ion-grid class="modal-header">
  <ion-row>
    <ion-col class="title">Reemplazar Ejecutivo</ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="description">
      Ingresa el RUT del nuevo ejecutivo para reemplazar el actual y trasladar la lista de clientes.
    </ion-col>
  </ion-row>
</ion-grid>
<form [formGroup]="execForm" (ngSubmit)="onSubmit()">
  <ion-grid [fixed]="true" class="main-box">
    <ion-col>
      <ion-label>RUT</ion-label>
      <ion-input formControlName="rut" placeholder="12345678-9" (ngModelChange)="initializeInfExecutive()"></ion-input>
      <div class="required-field"
        *ngIf="execForm.get('rut')?.invalid && (execForm.get('rut')?.dirty || execForm.get('rut')?.touched)">
        * Campo requerido.
      </div>
      <div class="error-label" *ngIf="msjRespValue">
        <em class="error-icon i-triangle-exclamation-filled-red"></em>
        <span class="error-message">{{ msjRespValue }}</span>
      </div>
      <div class="main-btns-row">
        <button type="button" class="search-btn" [disabled]="!execForm.valid || sameRut" (click)="searchRut()">Buscar
          Rut</button>
      </div>

      <ion-grid class="information-box">
        <ion-row>
          <ion-col class="ion-col-title">DATOS</ion-col>
          <ion-col class="ion-col-title">&nbsp;</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-col-text">Nombre y Apellidos</ion-col>
          <ion-col class="ion-col-value">{{ infExecutive.name }} {{ infExecutive.lastname }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-col-text">Teléfono o Celular</ion-col>
          <ion-col class="ion-col-value">{{ infExecutive.phone }}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-col-text">Correo electrónico</ion-col>
          <ion-col class="ion-col-value">{{ infExecutive.email }}</ion-col>
        </ion-row>
      </ion-grid>
      <div class="text-msj">
        <em class="error-icon i-triangle-exclamation-filled-red"></em>
        <span class="error-message">
          Al ingresar el RUT, el ejecutivo actual será reemplazado por el nuevo ejecutivo.
        </span>
      </div>
    </ion-col>
    <ion-col>

      <ion-label>Nombre Display</ion-label>
      <ion-input [disabled]="true">{{ infExecutive.displayName }}</ion-input>

      <ion-label>Cargo Display</ion-label>
      <ion-input [disabled]="true">{{ infExecutive.role.name }}</ion-input>

      <ion-label>Tipo de Persona</ion-label>
      <ion-input [disabled]="true">{{ infExecutive.role.name }}</ion-input>

      <ion-label>Estado</ion-label>
      <ion-input [disabled]="true">{{ infExecutive.status.name }}</ion-input>

      <div class="main-btns-row">
        <button mat-button mat-dialog-close type="button" class="cancel-btn">Cancelar</button>
        <button type="submit" class="save-btn" [disabled]="!rutValid">Reemplazar Ejecutivo</button>
      </div>

    </ion-col>
  </ion-grid>
</form>
