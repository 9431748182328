export const MAC_Http_Constants = {
  MAC_API_BaseUrlLocal: 'https://planvital-dev.apigee.net/portfoliomanager/v1',
  MAC_API_BaseUrlDev: 'https://planvital-dev.apigee.net/portfoliomanager/v1',
  MAC_API_BaseUrlQA: 'https://planvital-qa.apigee.net/portfoliomanager/v1',
  MAC_API_BaseUrlProd: 'https://planvital-prod.apigee.net/portfoliomanager/v1',

  Endpoints: {
    People: {
      Collaborators: {
        getAll: '/collaborators',
        getTotals: '/collaborators/total',
        getByRut: '/collaborators/',
        post: '/collaborators',
        put: '/collaborators/',
        delete: '/collaborators/',
        export: '/collaborators/export'
      },
      Administrators: {
        getAll: '/administrators',
        getByRut: '/administrators/',
        put: '/administrators/',
        delete: '/administrators/',
        export: '/administrators/export'
      },
      Supervisors: {
        getAll: '/supervisors',
        getByRut: '/supervisors/',
        put: '/supervisors/',
        delete: '/supervisors/',
        export: '/supervisors/export',
        clients: '/supervisors/'
      },
      Executives: {
        getAll: '/executives',
        getByRut: '/executives/',
        put: '/executives/',
        patch: '/executives/',
        delete: '/executives/',
        export: '/executives/export',
        clients: '/executives/'
      }
    },
    OrphanClients: {
      getAll: '/clients/orphans',
      assignExecutive: '/clients/',
      export: '/clients/orphans/export'
    }
  }

}


