<mat-toolbar class="top-toolbar">
    <ion-img class="logo" src="assets/svg/images/top-bar-left-logo-afp-white.svg" alt="logo-AFP"></ion-img>
    <span class="spacer"></span>
    <ion-img class="motto" src="assets/svg/images/top-bar-right-motto-afp-white.svg" alt="motto-AFP"></ion-img>
</mat-toolbar>

<mat-toolbar class="second-toolbar">
    <span class="welcome-message" [ngClass]="font.size">
        <strong>¡</strong>Bienvenido(a)&nbsp;<strong>Jaqueline!</strong>&nbsp;-&nbsp;<small>jaqueline@planvital.cl</small>
    </span>
    <span class="spacer"></span>
    <!-- ? Se deshabilita Temporalmente -->
    <!-- * Depende de la HU => mac-26 -->
    <!-- <ion-badge color="secondary" type="ios" [ngClass]="font.size">&nbsp;!&nbsp;</ion-badge>
    <button mat-icon-button aria-label="notifications">
        <mat-icon class="notify" [ngClass]="font.size">notifications</mat-icon>
    </button>
    <button mat-icon-button aria-label="text_increase">
        <mat-icon class="font-color" [ngClass]="font.size">text_increase</mat-icon>
    </button>
    <button mat-icon-button aria-label="text_decrease">
        <mat-icon class="font-color" [ngClass]="font.size">text_decrease</mat-icon>
    </button> -->
    <button mat-icon-button aria-label="contrast" (click)="toggleFilter(); $event.stopPropagation()">
        <mat-icon class="font-color" [ngClass]="font.size">contrast</mat-icon>
    </button>
</mat-toolbar>