/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MAC_HTTP_HEADERS } from '@global/constants/global.constants';
import { MAC_Http_Constants } from '@global/constants/mac-request.constants';
import { Collaborator, GetCollaboratorsRes, GetCollaboratorsTotalsRes } from '@interfaces/people.interface';
import { CollaboratorPayload } from '@models/people.model';
import { UtilProviderService } from '@providers/util/util.provider';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PeopleAllService {

  private httpOptions = { headers: MAC_HTTP_HEADERS };

  constructor(
    private http: HttpClient,
    private utilSV: UtilProviderService
  ) { }

  private params(paramsVar: any): string {
    let params = ``;
    if (paramsVar.nameRut) params = this.completeParameters(params, `search=${paramsVar.nameRut}`);
    if (paramsVar.agentType) params = this.completeParameters(params, `role=${paramsVar.agentType}`);
    if (paramsVar.agentStatus) params = this.completeParameters(params, `status=${paramsVar.agentStatus}`);
    if (paramsVar.startDate) params = this.completeParameters(params, `startDate=${paramsVar.startDate}`);
    if (paramsVar.endDate) params = this.completeParameters(params, `endDate=${paramsVar.endDate}`);
    if (paramsVar.order) params = this.completeParameters(params, `order=${paramsVar.order}`);
    if (paramsVar.orderBy) params = this.completeParameters(params, `orderBy=${paramsVar.orderBy}`);
    if (paramsVar.offset) params = this.completeParameters(params, `offset=${paramsVar.offset}`);
    if (paramsVar.limit) params = this.completeParameters(params, `limit=${paramsVar.limit}`);
    if (params != '') params = `?${params}`;
    return params;
  }

  private completeParameters(params: string, including: string) {
    return (params) ? (`${params}&${including}`) : (including);
  }

  public getCollaborators(paramsVar: any): Promise<GetCollaboratorsRes> {
    const params = this.params(paramsVar);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Collaborators.getAll}${params}`;
    return firstValueFrom<any>(this.http.get(url, this.httpOptions));
  }

  public getCollaboratorsTotals(): Promise<GetCollaboratorsTotalsRes> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Collaborators.getTotals}`;
    return firstValueFrom<any>(this.http.get(url, this.httpOptions));
  }

  public getCollaboratorByRut(rut: string): Promise<Collaborator> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Collaborators.getByRut}${this.utilSV.stringReplaceClean(rut)}`;
    return firstValueFrom<any>(this.http.get(url, this.httpOptions));
  }

  public postCreateCollaborator(payload: CollaboratorPayload): Promise<any> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Collaborators.post}`;
    return firstValueFrom<any>(this.http.post(url, payload, this.httpOptions));
  }

  public putCollaborator(payload: CollaboratorPayload): Promise<any> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Collaborators.put}${this.utilSV.stringReplaceClean(payload.rut)}`;
    return firstValueFrom<any>(this.http.put(url, payload, this.httpOptions));
  }

  public deleteCollaborators(rut): Promise<any> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Collaborators.delete}${this.utilSV.stringReplaceClean(rut)}`;
    return firstValueFrom<any>(this.http.delete(url, this.httpOptions));
  }

  public exportCollaborators(paramsVar: any): Promise<any> {
    const params = this.params(paramsVar);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Collaborators.export}${params}`;
    return this.getFileCall(url);
  }

  private getFileCall(url: string) {
    return firstValueFrom<any>(this.http.get(url, {
      headers: {
        ...this.httpOptions.headers
      },
      responseType: 'blob' as 'json'
    }));
  }

}
