import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GuiService } from '@providers/util/gui.service';
import { UtilProviderService } from '@providers/util/util.provider';
import { APIGeeService } from '@services/apigee/apigee.service';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private APIGeeKey = environment.APIGeeKey;
  private cookieExist: boolean | undefined;
  private token: string | undefined;
  private req: HttpRequest<unknown> | undefined;

  constructor(
    private utilSV: UtilProviderService,
    private guiSV: GuiService,
    private APIGeeSV: APIGeeService
  ) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.cookieExist = this.utilSV.checkFromCookies(this.APIGeeKey);
    this.token = this.utilSV.getFromCookies(this.APIGeeKey);
    this.req = request;
    if (!this.cookieExist || !this.token) {
      console.log('Pre-Call Refresh Token');
      this.refreshToken(request);
      console.log('After-Call Refresh Token');
    }
    if (this.token) {
      this.req = request.clone({
        setHeaders: {
          authorization: `Bearer ${this.token}`
        }
      });
    }
    return next.handle(this.req).pipe(
      // TODO: Error management
      catchError((err: HttpErrorResponse) => {
        const eMessage = err.error.message ? err.error.message : (err.message ? err.message : JSON.stringify(err));
        console.warn('eMessage: ', eMessage);
        if (err instanceof HttpErrorResponse && err.status === 401) { // Unauthorized
          if (this.req) {
            this.refreshToken(this.req);
          }
        }
        if (err.status === 502) { // 502 Bad Gateway
          this.guiSV.baselineToast('Servicio no Disponible', 'warning', 'bottom');
        }
        return throwError(err);
      })
    );
  }

  private async refreshToken(request: HttpRequest<unknown>): Promise<void> {
    console.log(request);
    return await this.APIGeeSV.refreshToken();
    // .finally(() => {
    //   console.log('finally Refresh Token');
    //   this.cookieExist = this.utilSV.checkFromCookies(this.APIGeeKey);
    //   this.token = this.utilSV.getFromCookies(this.APIGeeKey);
    //   this.req = request.clone({
    //     setHeaders: {
    //       authorization: `Bearer ${this.token}`
    //     }
    //   });
    // });
  }

}
