import { APIGeeData } from '../../shared/interfaces/APIGee.interface';

export class APIGeeConstants {

    public APIGeeKey = 'AGK';

    private dev: APIGeeData = {
        url: 'https://planvital-dev.apigee.net',
        credentials: {
            Username: 'OsboXAQ2Lf0Q7K9TFqfjdFovWevPxA7U',
            Password: '5wx9qZNjb2cAno97'
        }
    };

    private qa: APIGeeData = {
        url: 'https://planvital-qa.apigee.net',
        credentials: {
            Username: 'Lw70SeuEqen7W8ARJbx6VbpP7NQmA3Jo',
            Password: 'hwqQ1tZB7A7zVKMD'
        }
    };

    private prod: APIGeeData = {
        url: 'https://planvital-prod.apigee.net',
        credentials: {
            Username: '',
            Password: ''
        }
    };

    public getDevData(): APIGeeData {
        return this.dev;
    }
    public getQaData(): APIGeeData {
        return this.qa;
    }
    public getProdData(): APIGeeData {
        return this.prod;
    }
}


