import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, ToastController, ToastOptions } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class GuiService {

  constructor(
    private toastController: ToastController,
    private alertController: AlertController
  ) { }

  public async simpleAlert(
    message: string,
    title = 'Atencion',
    subtitle?: string
  ) {
    await this.presentAlert({
      message: message,
      header: title,
      subHeader: subtitle,
      mode: "md",
      keyboardClose: true,
      buttons: [
        {
          text: 'Aceptar',
          role: 'confirm'
        },
      ]
    });
  }

  public async baselineToast(
    message: string,
    type: 'success' | 'error' | 'warning' | 'info' = 'info',
    position: "bottom" | "top" | "middle" | undefined = "bottom",
    icon?: string,
    seconds?: number
  ) {
    const duration = seconds ? seconds * 1000 : 3000;
    await this.presentToast({
      duration: duration,
      icon: icon ? icon : 'alert-circle-outline',
      message: message,
      cssClass: `${type}-toast`,
      position: position
    });
  }

  public async stackedToast(
    message: string,
    type: 'success' | 'error' | 'warning' | 'info' = 'info',
    position: "bottom" | "top" | "middle" | undefined = "bottom",
    icon?: string,
    seconds?: number
  ) {
    const duration = seconds ? seconds * 1000 : 3000;
    await this.presentToast({
      duration: duration,
      icon: icon,
      message: message,
      cssClass: `${type}-toast`,
      position: position,
      layout: "stacked"
    });
  }

  private async presentAlert(opts: AlertOptions) {
    const alert = await this.alertController.create(opts);
    await alert.present();
  }

  private async presentToast(opts: ToastOptions) {
    const toast = await this.toastController.create(opts);
    await toast.present();
  }

}
