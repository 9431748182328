import { HttpErrorCodes } from 'app/shared/interfaces/http.interface';

export const RELOAD_APP_TIME = 10000;

export const MAC_HTTP_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, HEAD',
  'Access-Control-Allow-Headers': 'Content-Type, Accept, X-Requested-With, remember-me',
  'Content-Type': 'application/json',
  'X-Content-Type-Options': 'nosniff',
  'X-Frame-Options': 'ALLOW-FROM http://localhost:8100'
};

export const APIGee_HTTP_HEADERS = {
  'Accept': '*/*',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-Content-Type-Options': 'nosniff'
};

export const HTTP_ERROR_CODES: HttpErrorCodes = {
  unauthorized: {
    code: 401,
    message: 'No autorizado',
    messageDescription: 'Usuario sin autorización.'
  },
  entityTooLarge: {
    code: 413,
    message: 'Archivo muy grande',
    messageDescription: 'Archivo muy grande.'
  },
  internalServerError: {
    code: 500,
    message: 'Error',
    messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
  },
  badGatewayError: {
    code: 502,
    message: 'Error',
    messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
  },
  badRequest: {
    code: 400,
    message: 'Bad request',
    messageDescription: 'Hubo un error en la petición al servidor.'
  },
  notFound: {
    code: 404,
    message: 'Not found',
    messageDescription: 'No encontrado.'
  }
};

export enum HttpResponseTypes {
  Arraybuffer = 'arraybuffer',
  Json = 'json',
  Blob = 'blob',
  Text = 'text'
}

export const SERVER_ERROR_MESSAGE = 'En estos momentos no podemos atender tu solicitud';

export const APP_ROUTES = {
  main: '',
  notFound: '404',
  splash: 'loading',
  login: 'ingreso',
  administrators: {
    home: 'administrator/dashboard',
    people: {
      route: 'administrator/people',
      sel: {
        administrators: 'admin',
        supervisors: 'super',
        executives: 'exec'
      },
      maintainer: 'administrator/people/editor',
      assignSupervisor: 'administrator/people/assign-supervisor'
    },
    orphanClients: 'administrator/orphan-clients',
    myProfile: 'administrator/my-profile:administrator',
    settings: 'administrator/settings'
  },
  supervisors: {
    home: 'supervisor/dashboard',
    myExecutives: 'supervisor/my-executives',
    myClients: 'supervisor/my-clients',
    myProfile: 'supervisor/my-profile:supervisor'
  },
  branches: {
    home: 'branch/check-customer'
  }
};
