import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'styleSan'
})
export class StyleSanPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(resourceUrl: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(resourceUrl);
  }

}
