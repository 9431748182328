import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DT_Constants } from '@global/constants/dt-tables.constants';
import { VoluntaryProducts } from '@global/constants/people.constants';
import { ModalClientListPeople } from '@interfaces/ModalClientListPeople.interface';
import { GuiService } from '@providers/util/gui.service';
import { UtilProviderService } from '@providers/util/util.provider';
import { PeopleSupervisorsService } from '@services/people/people-supervisors.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { clean, format, validate } from 'rut.js';
@Component({
  selector: 'app-modal-list-client-people',
  templateUrl: 'modal-list-client-people.component.html',
  styleUrls: ['modal-list-client-people.component.scss']
})

export class ModalListClientComponent implements OnInit {
  public constants = new DT_Constants();
  public dtOptions: DataTables.Settings = this.constants.getDtOptions();
  public recordsTotal = 0;
  public rutSupervisor: string;
  public voluntaryProducts = VoluntaryProducts;
  isLoadingDownload = false;
  private getParams = {
    offset: 0,
    limit: 0,
    order: '',
    orderBy: '',
    nameRut: '',
    antiquity: '',
    rimFrom: '',
    rimTo: '',
    voluntaryProduct: ''
  };
  public filtersForm: FormGroup<any> = new FormGroup({});

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalClientListPeople,
    public dialogRef: MatDialogRef<ModalListClientComponent>,
    private spinner: NgxSpinnerService,
    private pplSuperSV: PeopleSupervisorsService,
    private utilSV: UtilProviderService,
    private fb: FormBuilder,
    private guiSV: GuiService
  ) {
    this.rutSupervisor = this.data.data.rut;
    this.dataTableServer();
  }

  ngOnInit() {
    this.initializePage();
  }

  private initializePage() {
    this.filtersForm = this.fb.group({
      nameRut: '',
      antiquity: '',
      rimFrom: '',
      rimTo: '',
      voluntaryProduct: ''
    });
  }

  public filterTable(form: FormGroup) {
    this.getParams.nameRut = form.value.nameRut ? form.value.nameRut : '';
    this.getParams.antiquity = form.value.antiquity ? form.value.antiquity : '';
    this.getParams.rimFrom = form.value.rimFrom ? form.value.rimFrom : '';
    this.getParams.rimTo = form.value.rimTo ? form.value.rimTo : '';
    this.getParams.voluntaryProduct = form.value.voluntaryProduct ? form.value.voluntaryProduct : '';
    $('#peopleTableSupervisors').DataTable().ajax.reload();
  }

  onClosed() {
    this.dialogRef.close();
  }

  public orderTable(order: any) {
    const column = ['assignment_date', 'name', 'rut'];
    this.getParams.orderBy = column[order.column];
    this.getParams.order = order.dir;
  }

  public dataTableServer() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.getParams.offset = dataTablesParameters.start;
      this.getParams.limit = dataTablesParameters.length;
      this.orderTable(dataTablesParameters.order[0]);

      this.spinner.show();
      const prom = this.pplSuperSV.getSupervisorsClients(this.rutSupervisor, this.getParams);
      prom.then((response) => {
        callback({
          recordsTotal: response.totalElements ? response.totalElements : 0,
          recordsFiltered: response.totalElements ? response.totalElements : 0,
          data: response.data ? response.data : []
        });
        this.recordsTotal = response.totalElements;
      }).catch((err) => {
        const errMessage = err.error.message ? err.error.message : (err.message ? err.message : JSON.stringify(err));
        this.guiSV.simpleAlert(errMessage, 'Error');
      }).finally(() => this.spinner.hide());
    };
    this.dtOptions.columns = [
      {
        title: 'FECHA DE ASIGNACIÓN',
        data: null,
        className: 'dt-head-center dt-body-center',
        render(data) {
          if (data.assignmentDate) {
            return new Date(data.assignmentDate).toLocaleDateString();
          } else {
            return 'Sin Fecha';
          }
        }
      },
      {
        title: 'CLIENTE',
        data: null,
        className: 'dt-head-center dt-body-left',
        render(data) {
          if (data.name || data.lastname) {
            return `${data.name} ${data.lastname}`;
          } else {
            return 'Sin Nombre';
          }
        }
      },
      {
        title: 'RUT',
        data: null,
        className: 'dt-head-center dt-body-right dt-body-nowrap',
        render(data) {
          if (data.rut) {
            if (validate(clean(data.rut))) {
              return format(clean(data.rut), { dots: false });
            } else {
              return 'Rut Invalido';
            }
          } else {
            return 'Sin Rut';
          }
        }
      },
      {
        title: 'CORREO ELECTRÓNICO',
        data: null,
        className: 'dt-head-center dt-body-left',
        orderable: false,
        render(data) {
          if (data.email) {
            return data.email;
          } else {
            return 'Sin Correo';
          }
        }
      },
      {
        title: 'TELÉFONO',
        data: null,
        className: 'dt-head-center dt-body-right',
        orderable: false,
        render(data) {
          if (data.phone) {
            return data.phone;
          } else {
            return 'Sin Telefono';
          }
        }
      },
      {
        title: 'RIM',
        data: null,
        className: 'dt-head-center dt-body-left',
        orderable: false,
        render(data) {
          if (data.rim) {
            return data.rim;
          } else {
            return 'Sin RIM';
          }
        }
      },
      {
        title: 'PRODUCTOS VOLUNTARIOS',
        data: null,
        className: 'dt-head-center dt-body-center',
        orderable: false,
        render(data) {
          if (data.products.length > 0) {
            return data.products.map((product) => product.name).join(' / ');
          } else {
            return 'Ninguno';
          }
        }
      },
      {
        title: 'ANTIGÜEDAD DEL CLIENTE',
        data: null,
        className: 'dt-head-center dt-body-center',
        orderable: false,
        render(data) {
          const afilDate: any = new Date(data.affiliationDate);
          const currentDate: any = new Date();
          const monthsSeniority = Math.trunc(Math.floor(currentDate - afilDate) / (1000 * 60 * 60 * 24 * 30));
          return `${monthsSeniority} meses`;
        }
      },
    ];
  }

  public onDownload() {
    this.isLoadingDownload = true;
    this.getParams.limit = 0;
    this.getParams.offset = 0;
    this.pplSuperSV.exportSupervisorClients(this.rutSupervisor, this.getParams).then((response) => {
      this.utilSV.onDownloadXLSX(
        'ClientsAssigned.xlsx',
        response
      );
    }).finally(() => this.isLoadingDownload = false);
  }

}
