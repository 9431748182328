import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DT_Constants } from '@global/constants/dt-tables.constants';
import { Roles, Statuses } from '@global/constants/people.constants';
import { ModalClientListPeople } from '@interfaces/ModalClientListPeople.interface';
import { GuiService } from '@providers/util/gui.service';
import { UtilProviderService } from '@providers/util/util.provider';
import { PeopleSupervisorsService } from '@services/people/people-supervisors.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { clean, format, validate } from 'rut.js';

@Component({
  selector: 'app-modal-list-executives-people',
  templateUrl: 'modal-list-executives-people.component.html',
  styleUrls: ['modal-list-executives-people.component.scss']
})

export class ModalListExecutivesComponent implements OnInit {
  public constants = new DT_Constants();
  public dtOptions: DataTables.Settings = this.constants.getDtOptions();
  public recordsTotal = 0;
  public rutSupervisor: string;
  public statusSupervisor: string;
  public roles = Roles.filter((obj) => {
    return obj.id == 4 || obj.id == 5;
  });
  public statuses = Statuses.filter((obj) => {
    return obj.id != 5 && obj.id != 6;
  });
  isLoadingDownload = false;
  private getParams = {
    offset: 0,
    limit: 0,
    order: '',
    orderBy: '',
    nameRut: '',
    agentRol: '',
    agentStatus: '',
    startDate: '',
    endDate: ''
  };
  public filtersForm: FormGroup<any> = new FormGroup({});

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalClientListPeople,
    public dialogRef: MatDialogRef<ModalListExecutivesComponent>,
    private spinner: NgxSpinnerService,
    private pplSuperSV: PeopleSupervisorsService,
    private utilSV: UtilProviderService,
    private fb: FormBuilder,
    private guiSV: GuiService
  ) {
    this.rutSupervisor = this.data.data.rut;
    this.statusSupervisor = this.data.data.status.name;
    this.dataTableServer();
  }

  ngOnInit() {
    this.initializePage();
  }

  private initializePage() {
    this.filtersForm = this.fb.group({
      nameRut: '',
      agentRol: '',
      agentStatus: '',
      startDate: '',
      endDate: ''
    });
  }

  onClosed() {
    this.dialogRef.close();
  }

  public filterTable(form: FormGroup) {
    this.getParams.nameRut = form.value.nameRut ? form.value.nameRut : '';
    this.getParams.agentRol = form.value.agentRol ? form.value.agentRol : '';
    this.getParams.agentStatus = form.value.agentStatus ? form.value.agentStatus : '';
    const pipe = new DatePipe('es-CL');
    const startDate = pipe.transform(form.value.startDate, 'YYYY-MM-dd');
    const endDate = pipe.transform(form.value.endDate, 'YYYY-MM-dd');
    this.getParams.startDate = startDate ? startDate : '';
    this.getParams.endDate = endDate ? endDate : '';
    $('#peopleTableSupervisors').DataTable().ajax.reload();
  }

  public orderTable(order: any) {
    const column = ['admissiondate', 'name', 'rut', '', '', 'rol', '', '', 'status'];
    this.getParams.orderBy = column[order.column];
    this.getParams.order = order.dir;
  }

  public dataTableServer() {
    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.getParams.offset = dataTablesParameters.start;
      this.getParams.limit = dataTablesParameters.length;
      this.orderTable(dataTablesParameters.order[0]);
      this.spinner.show();
      const prom = this.pplSuperSV.getSupervisorsExecutives(this.rutSupervisor, this.getParams);
      prom.then((response) => {
        callback({
          recordsTotal: response.totalElements ? response.totalElements : 0,
          recordsFiltered: response.totalElements ? response.totalElements : 0,
          data: response.data ? response.data : []
        });
        this.recordsTotal = response.totalElements;
      }).catch((err) => {
        const errMessage = err.error.message ? err.error.message : (err.message ? err.message : JSON.stringify(err));
        this.guiSV.simpleAlert(errMessage, 'Error');
      }).finally(() => this.spinner.hide());
    };
    this.dtOptions.columns = [
      {
        title: 'FECHA DE INGRESO',
        data: null,
        className: 'dt-head-center dt-body-center',
        render(data) {
          if (data.createDate) {
            return new Date(data.createDate).toLocaleDateString();
          } else {
            return 'Sin Fecha';
          }
        }
      },
      {
        title: 'NOMBRE Y APELLIDO',
        data: null,
        className: 'dt-head-center dt-body-left',
        render(data) {
          if (data.name || data.lastname) {
            return `${data.name} ${data.lastname}`;
          } else {
            return 'Sin Nombre';
          }
        }
      },
      {
        title: 'RUT',
        data: null,
        className: 'dt-head-center dt-body-right dt-body-nowrap',
        render(data) {
          if (data.rut) {
            if (validate(clean(data.rut))) {
              return format(clean(data.rut), { dots: false });
            } else {
              return 'Rut Invalido';
            }
          } else {
            return 'Sin Rut';
          }
        }
      },
      {
        title: 'CORREO ELECTRÓNICO',
        data: null,
        className: 'dt-head-center dt-body-left',
        orderable: false,
        render(data) {
          if (data.email) {
            return data.email;
          } else {
            return 'Sin Correo';
          }
        }
      },
      {
        title: 'TELÉFONO',
        data: null,
        className: 'dt-head-center dt-body-right',
        orderable: false,
        render(data) {
          if (data.phone) {
            return data.phone;
          } else {
            return 'Sin Telefono';
          }
        }
      },
      {
        title: 'TIPO DE EJECUTIVO',
        data: null,
        className: 'dt-head-center dt-body-left',
        render(data) {
          if (data.rol.name) {
            return data.rol.name;
          } else {
            return 'Sin Información';
          }
        }
      },
      {
        title: 'CIUDAD',
        data: null,
        className: 'dt-head-center dt-body-center',
        orderable: false,
        render(data) {
          if (data.city) {
            return data.city;
          } else {
            return 'Sin Información';
          }
        }
      },
      {
        title: 'CLIENTES ASIGNADOS',
        data: null,
        className: 'dt-head-center dt-body-center',
        orderable: false,
        render(data) {
          if (data.totalClients) {
            return `${data.totalClients} clientes`;
          } else {
            return '-';
          }
        }
      },
      {
        title: 'ESTADO',
        data: null,
        className: 'dt-head-center dt-body-center',
        render(data) {
          if (data.status && data.status.name) {
            switch (data.status.name) {
              case 'Activo':
                return `<div class="custom-cell pastelgreen d-center">
                          ${data.status.name}
                        </div>`;
              case 'Eliminado':
                return `<div class="custom-cell lightblue d-center">
                          ${data.status.name}
                        </div>`;
              case 'Licencia':
                return `<div class="custom-cell salmon d-center">
                          ${data.status.name}
                        </div>`;
              case 'Vacaciones':
                return `<div class="custom-cell pastelorange d-center">
                          ${data.status.name}
                        </div>`;
              default:
                return `<div class="custom-cell d-center">
                          ${data.status.name}
                        </div>`;
            }
          } else {
            return 'Sin Estado';
          }
        }
      },
    ];
  }

  public onDownload() {
    this.isLoadingDownload = true;
    this.getParams.limit = 0;
    this.getParams.offset = 0;
    this.pplSuperSV.exportSupervisorExecutives(this.rutSupervisor, this.getParams).then((response) => {
      this.utilSV.onDownloadXLSX(
        'ExecutivesAssigned.xlsx',
        response
      );
    }).finally(() => this.isLoadingDownload = false);
  }

}
