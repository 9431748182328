import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalExecutiveReplacePeople } from '@interfaces/ModalExecutiveReplacePeople.interface';
import { Executive } from '@interfaces/people.interface';
import { GuiService } from '@providers/util/gui.service';
import { UtilProviderService } from '@providers/util/util.provider';
import { PeopleExecutivesService } from '@services/people/people-executives.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-modal-replace-executive',
  templateUrl: './modal-replace-executive.component.html',
  styleUrls: ['./modal-replace-executive.component.scss']
})
export class ModalReplaceExecutiveComponent implements OnInit {
  execForm: FormGroup = new FormGroup({});
  rutValid: boolean;
  infExecutive: Executive;
  sameRut: boolean;
  msjRespValue: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalExecutiveReplacePeople,
    public dialogRef: MatDialogRef<ModalReplaceExecutiveComponent>,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private utilSV: UtilProviderService,
    private guiSV: GuiService,
    private pplExecSV: PeopleExecutivesService
  ) {
    this.infExecutive = {
      rut: '',
      name: '',
      lastname: '',
      phone: '',
      email: '',
      admissionDate: new Date(),
      displayName: '',
      displayRole: '',
      city: '',
      role: {
        id: 0,
        name: ''
      },
      status: {
        id: 0,
        name: ''
      },
      clients: 0
    };
    this.rutValid = false;
    this.sameRut = false;
    this.msjRespValue = '';
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  public initializeInfExecutive() {
    if (this.utilSV.stringReplaceClean(this.execForm.value.rut) !=
      this.utilSV.stringReplaceClean(this.data.data.rut)) {
      this.sameRut = false;
      if (this.execForm.value.rut != this.infExecutive.rut) {
        this.initializeDataExecutive();
      }
    } else {
      this.sameRut = true;
      this.initializeDataExecutive();
      this.msjRespValue = 'El RUT debe ser distinto al ejecutivo a reemplazar';
    }

  }

  public initializeDataExecutive() {
    this.infExecutive = {
      rut: '',
      name: '',
      lastname: '',
      phone: '',
      email: '',
      admissionDate: new Date(),
      displayName: '',
      displayRole: '',
      city: '',
      role: {
        id: 0,
        name: ''
      },
      status: {
        id: 0,
        name: ''
      },
      clients: 0
    };
    this.rutValid = false;
    this.msjRespValue = '';
  }

  private initializeForm() {
    this.execForm = this.fb.group({
      rut: ['', Validators.required]
    });
  }

  public onSubmit() {
    this.spinner.show();
    const prom = this.pplExecSV.postReplaceExecutive(this.data.data.rut, this.execForm.value.rut);
    prom.then(() => {
      this.dialogRef.close(ModalReplaceExecutiveComponent);
      if (this.data.peopleTableReload != '') $(this.data.peopleTableReload).DataTable().ajax.reload();
      this.guiSV.simpleAlert('Ejecutivo reemplazado exitosamente', 'Atencion');
    }).catch((err) => {
      const errMessage = err.error.message ? err.error.message : (err.message ? err.message : JSON.stringify(err));
      this.guiSV.simpleAlert(errMessage, 'Error');
    }).finally(() => this.spinner.hide());
  }

  public searchRut() {
    if (this.utilSV.rutValidator(this.execForm.value.rut)) {
      this.spinner.show();
      const prom = this.pplExecSV.getExecutiveByRut(this.execForm.value.rut);
      prom.then((response) => {
        this.infExecutive = response;
        this.rutValid = true;
      }).catch((err) => {
        const errMessage = err.error.message ? err.error.message : (err.message ? err.message : JSON.stringify(err));
        this.msjRespValue = errMessage;
      }).finally(() => this.spinner.hide());
    } else {
      this.msjRespValue = 'Rut Invalido';
    }
  }
}
