<div class="container-modal">
    <span class="title">Asignar manualmente</span>
    <span class="subtitle">Ingresa el RUT de la persona para asignar manualmente el cliente huérfano seleccionado</span>
    <div class="container-body">
        <div class="body-left">
            <span class="left-title">Datos del Cliente Huérfano</span>
            <span class="left-section-title">CLIENTE HUÉRFANO</span>
            <div class="box-section">
                <span class="section-field">Nombre y Apellidos</span>
                <span class="section-value">{{data.data.name}} {{data.data.lastname}}</span>
            </div>
            <div class="box-section">
                <span class="section-field">R.I.M.</span>
                <span class="section-value">$ {{data.data.rim}}</span>
            </div>
            <div class="box-section">
                <span class="section-field">Productos Voluntarios</span>
                <span class="section-value">{{data.data.productsName}}</span>
            </div>

            <div class="box-section">
                <span class="section-field">Antigüedad</span>
                <span class="section-value">{{data.data.antiquity}} meses</span>
            </div>
        </div>
        <div class="body-right">
            <span class="right-title">Persona de asignación</span>
            <form class="box-section" [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
                <span class="title-search">RUT</span>
                <input id="rut" formControlName="rut" type="text" class="input-search" required>
                <span *ngIf="rutIsNull" class="field-required">
                    Se requiere el rut
                </span>
                <div class="error-label" *ngIf="errorMessages">
                    <em class="error-icon i-triangle-exclamation-filled-red"></em>
                    <span class="error-message">{{errorMessages}}</span>
                </div>
                <button class="button-search">Buscar Rut</button>
            </form>
            <div class="container-executive" *ngIf="dataExecutive">
                <span class="left-section-title">DATOS</span>
                <div class="box-field-right">
                    <span class="title-field-right">Nombre y Apellidos</span>
                    <span class="value-field-right">{{dataExecutive.name}} {{dataExecutive.lastname}}</span>
                </div>
                <div class="box-field-right">
                    <span class="title-field-right">Teléfono o Celular</span>
                    <span class="value-field-right">{{dataExecutive.phone}}</span>
                </div>
                <div class="box-field-right">
                    <span class="title-field-right">Correo electrónico</span>
                    <span class="value-field-right">{{dataExecutive.email}}</span>
                </div>
                <div class="box-input-disable">
                    <span class="title-field">Tipo de Personas</span>
                    <input type="text" class="input-field-disables" [(ngModel)]="dataExecutive.role.name">
                </div>
                <div class="box-input-disable">
                    <span class="title-field">Estado</span>
                    <input type="text" class="input-field-disables" [(ngModel)]="dataExecutive.status.name">
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="button-cancel" [mat-dialog-close]="false">Cancelar</button>
        <button class="button-assign" (click)="assignExecutive()">Asignar Cliente</button>
    </div>
</div>