export class DT_Constants {
    private dtOptions: DataTables.Settings = {
        searching: false,
        paging: true,
        pagingType: 'full_numbers',
        serverSide: true,
        lengthChange: true,
        // pageLength: 5,
        lengthMenu: [5, 10, 25, 50, 75, 100, 500, 750, 1000],
        scrollX: false,
        processing: true,
        deferRender: true,
        destroy: true,
        // dom: 'Bfrtip',
        order: [[0, 'asc']],
        responsive: true,
        stateSave: true,
        language: {
            processing: 'Procesando...',
            search: 'Buscar:',
            searchPlaceholder: 'Ingrese información...',
            lengthMenu: 'Mostrar _MENU_ elementos',
            info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
            infoEmpty: 'Mostrando ningún elemento.',
            infoFiltered: '(filtrado _MAX_ elementos total)',
            infoPostFix: '',
            loadingRecords: 'Cargando registros...',
            zeroRecords: 'No se encontraron registros',
            emptyTable: 'No hay datos disponibles',
            paginate: {
                first: '<<',
                previous: '<',
                next: '>',
                last: '>>'
            },
            aria: {
                sortAscending: ': Activar para ordenar la tabla en orden ascendente',
                sortDescending: ': Activar para ordenar la tabla en orden descendente'
            }
        }
    };

    /**
      * Retorna la opciones comunes de DataTable
      */
    public getDtOptions(): any {
        return this.dtOptions;
    }

}