import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalManualAssignment } from '../../../interfaces/ModalManualAssignment';
import { Collaborator } from '../../../interfaces/people.interface';
import { OrphanClientsService } from '../../../services/orphan-clients/orphan-clients.service';
import { PeopleAllService } from '../../../services/people/people-all.service';
@Component({
  selector: 'app-modal-manual-assignment',
  templateUrl: 'modal-manual-assignment.component.html',
  styleUrls: ['modal-manual-assignment.component.scss']
})

export class ModalManualAssignmentComponent {
  rutIsNull = false;
  errorMessages: string | null;
  dataExecutive: Collaborator | null;
  checkoutForm = this.formBuilder.group({
    rut: ''
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalManualAssignment,
    public dialogRef: MatDialogRef<ModalManualAssignmentComponent>,
    private peopleAllService: PeopleAllService,
    private orphanClientsService: OrphanClientsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    this.dataExecutive = null;
    this.errorMessages = null;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.checkoutForm.valid) {
      this.rutIsNull = true;
    } else {
      this.rutIsNull = false;
      this.spinner.show();
      this.dataExecutive = null;
      this.peopleAllService.getCollaboratorByRut(this.checkoutForm.value.rut || '').then((response) => {
        if (response.role.id !== 4) {
          this.errorMessages = 'El rut ingresado no corresponde a un ejecutivo';
        } else {
          if (response.status.id === 1) {
            this.errorMessages = null;
            this.dataExecutive = response;
          } else {
            this.errorMessages = 'El ejecutivo ingresado no se encuentra activo';
          }
        }
      })
        .catch(() => {
          this.errorMessages = 'No se encontró el ejecutivo';
        })
        .finally(() => {
          this.spinner.hide();
        });
    }
  }

  assignExecutive(): void {
    if (this.checkoutForm.valid) {
      if (this.dataExecutive) {
        this.spinner.show();
        this.orphanClientsService.assignExecutive(this.data.data.rut, this.dataExecutive?.rut || '').then((response) => {
          console.log(response);
          this.onSuccessAssign();
        })
          .catch(() => {
            this.errorMessages = 'No se pudo asignar el ejecutivo';
          })
          .finally(() => {
            this.spinner.hide();
          });
      }
      else {
        this.errorMessages = 'Busque un ejecutivo para poder asignar';
      }
    } else {
      this.errorMessages = 'Busque un ejecutivo para poder asignar';
    }
  }

  onSuccessAssign(): void {
    this.dialogRef.close(true);
  }
}