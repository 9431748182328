import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MAC_HTTP_HEADERS } from '../../../global/constants/global.constants';
import { MAC_Http_Constants } from '../../../global/constants/mac-request.constants';

@Injectable({
    providedIn: 'root'
})
export class OrphanClientsService {
    private httpOptions = { headers: MAC_HTTP_HEADERS };
    constructor(
        private http: HttpClient
    ) { }

    public getAll(offset = 0, limit = 10, paramsFilter = '') {
        const params = `?offset=${offset}&limit=${limit}${paramsFilter ? paramsFilter : ''}`;
        const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.OrphanClients.getAll}${params}`;
        return firstValueFrom<any>(this.http.get(url, this.httpOptions));
    }

    public assignExecutive(rut: string, executiveRut: string) {
        const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.OrphanClients.assignExecutive}${rut}`;
        const body = {
            executiveRut
        };
        return firstValueFrom<any>(this.http.patch(url, body, this.httpOptions));
    }

    public exportOrphanClients(paramsFilter = '') {
        const params = paramsFilter ? `?${paramsFilter}` : '';
        const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.OrphanClients.export}${params}`;
        return this.getFileCall(url);
    }

    private getFileCall(url: string) {
        return firstValueFrom<any>(this.http.get(url, {
            headers: {
                ...this.httpOptions.headers
            },
            responseType: 'blob' as 'json'
        }));
    }
}