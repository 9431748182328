import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIGee_HTTP_HEADERS } from '@global/constants/global.constants';
import { APIGeeData, APIGeeTokenResponse } from '@interfaces/APIGee.interface';
import { UtilProviderService } from '@providers/util/util.provider';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class APIGeeService {

  private APIGeeKey = environment.APIGeeKey;
  private httpOptions = { headers: APIGee_HTTP_HEADERS };
  private APIGeeCurrentData: APIGeeData = environment.APIGeeData;
  private httpClient: HttpClient;

  constructor(
    private httpBackend: HttpBackend,
    private utilSV: UtilProviderService
  ) {
    this.httpClient = new HttpClient(httpBackend);
    const credentials = `${this.APIGeeCurrentData.credentials.Username}:${this.APIGeeCurrentData.credentials.Password}`;
    this.httpOptions.headers['Authorization'] = `Basic ${btoa(credentials)}`;
  }

  public async getToken(): Promise<void> {
    const url = `${this.APIGeeCurrentData.url}/oauth/token`;
    const body = new URLSearchParams();
    body.set("grant_type", "client_credentials");
    const tokenProm = firstValueFrom<any>(this.httpClient.post<APIGeeTokenResponse>(url, body, this.httpOptions));
    return await tokenProm.then((tokenRes) => {
      console.log('Durin GET Token');
      if (tokenRes) {
        const expiration = new Date();
        expiration.setSeconds(expiration.getSeconds() + Number(tokenRes.expires_in));
        this.utilSV.setToCookies(this.APIGeeKey, tokenRes.access_token, expiration, '/');
      }
    }).catch(console.error);
  }

  public async refreshToken(): Promise<void> {
    this.utilSV.removeFromCookies(this.APIGeeKey);
    return await this.getToken();
  }

}
