import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DataTablesModule } from 'angular-datatables';
import { MaterialModule } from 'app/material.module';
import { ModalManualAssignmentComponent } from './orphan-clients/modal-manual-assignment/modal-manual-assignment.component';
import { ModalListClientExecutiveComponent } from './people/modal-list-client-executive/modal-list-client-executive.component';
import { ModalListClientComponent } from './people/modal-list-client-supervisor/modal-list-client-people.component';
import { ModalListExecutivesComponent } from './people/modal-list-executives-supervisor/modal-list-executives-people.component';
import { ModalReplaceExecutiveComponent } from './people/modal-replace-executive/modal-replace-executive.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
@NgModule({
  declarations: [
    ToolbarComponent,
    ModalListClientComponent,
    ModalManualAssignmentComponent,
    ModalListExecutivesComponent,
    ModalListClientExecutiveComponent,
    ModalReplaceExecutiveComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    MaterialModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ToolbarComponent,
    ModalListClientComponent,
    ModalManualAssignmentComponent,
    ModalListExecutivesComponent,
    ModalListClientExecutiveComponent,
    ModalReplaceExecutiveComponent,
  ]
})
export class ComponentsModule { }
