import { APIGeeConstants } from '@global/constants/login.constants';
import { MAC_Http_Constants } from '@global/constants/mac-request.constants';

export const environment = {
  production: false,
  name: 'development',
  APIGeeData: {
    url: new APIGeeConstants().getDevData().url,
    credentials: {
      Username: '',
      Password: ''
    }
  },
  APIGeeKey: `${new APIGeeConstants().APIGeeKey}-DEV`,
  MACBaseUrl: MAC_Http_Constants.MAC_API_BaseUrlDev
};
