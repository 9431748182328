/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MAC_HTTP_HEADERS } from '@global/constants/global.constants';
import { MAC_Http_Constants } from '@global/constants/mac-request.constants';
import { GetSupervisorClients, GetSupervisorsExecutives, GetSupervisorsRes, Supervisor } from '@interfaces/people.interface';
import { CollaboratorPayload } from '@models/people.model';
import { UtilProviderService } from '@providers/util/util.provider';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PeopleSupervisorsService {

  private httpOptions = { headers: MAC_HTTP_HEADERS };

  constructor(
    private http: HttpClient,
    private utilSV: UtilProviderService
  ) { }

  private params(paramsVar: any): string {
    let params = ``;
    if (paramsVar.nameRut) params = this.completeParameters(params, `search=${paramsVar.nameRut}`);
    if (paramsVar.agentStatus) params = this.completeParameters(params, `status=${paramsVar.agentStatus}`);
    if (paramsVar.agentType) params = this.completeParameters(params, `rol=${paramsVar.agentType}`);
    if (paramsVar.agentRol) params = this.completeParameters(params, `role=${paramsVar.agentRol}`);
    if (paramsVar.startDate) params = this.completeParameters(params, `startDate=${paramsVar.startDate}`);
    if (paramsVar.endDate) params = this.completeParameters(params, `endDate=${paramsVar.endDate}`);
    if (paramsVar.order) params = this.completeParameters(params, `order=${paramsVar.order}`);
    if (paramsVar.orderBy) params = this.completeParameters(params, `orderBy=${paramsVar.orderBy}`);
    if (paramsVar.offset) params = this.completeParameters(params, `offset=${paramsVar.offset}`);
    if (paramsVar.limit) params = this.completeParameters(params, `limit=${paramsVar.limit}`);
    if (paramsVar.antiquity) params = this.completeParameters(params, `antiquity=${paramsVar.antiquity}`);
    if (paramsVar.rimFrom) params = this.completeParameters(params, `rimFrom=${paramsVar.rimFrom}`);
    if (paramsVar.rimTo) params = this.completeParameters(params, `rimTo=${paramsVar.rimTo}`);
    if (paramsVar.voluntaryProduct) params = this.completeParameters(params, `products=${paramsVar.voluntaryProduct}`);
    if (params != '') params = `?${params}`;
    return params;
  }

  private completeParameters(params: string, including: string) {
    return (params) ? (`${params}&${including}`) : (including);
  }

  public getSupervisors(paramsVar: any): Promise<GetSupervisorsRes> {
    const params = this.params(paramsVar);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.getAll}${params}`;
    return firstValueFrom<any>(this.http.get(url, this.httpOptions));
  }

  public getSupervisorByRut(rut: string): Promise<Supervisor> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.getByRut}${this.utilSV.stringReplaceClean(rut)}`;
    return firstValueFrom<any>(this.http.get(url, this.httpOptions));
  }

  public putSupervisor(payload: CollaboratorPayload): Promise<any> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.put}${this.utilSV.stringReplaceClean(payload.rut)}`;
    return firstValueFrom<any>(this.http.put(url, payload, this.httpOptions));
  }

  public deleteSupervisor(rut: string): Promise<any> {
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.delete}${this.utilSV.stringReplaceClean(rut)}`;
    return firstValueFrom<any>(this.http.delete(url, this.httpOptions));
  }

  public exportSupervisors(paramsVar: any): Promise<any> {
    const params = this.params(paramsVar);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.export}${params}`;
    return this.getFileCall(url);
  }

  public getSupervisorsClients(rut: string, paramsVar: any): Promise<GetSupervisorClients> {
    const params = this.params(paramsVar);
    rut = this.utilSV.stringReplaceClean(rut);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.clients}${rut}/clients${params}`;
    return firstValueFrom<any>(this.http.get(url, this.httpOptions));
  }

  public exportSupervisorClients(rut: string, paramsVar: any): Promise<any> {
    const params = this.params(paramsVar);
    rut = this.utilSV.stringReplaceClean(rut);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.clients}${rut}/clients/export${params}`;
    return this.getFileCall(url);
  }

  public getSupervisorsExecutives(rut: string, paramsVar: any): Promise<GetSupervisorsExecutives> {
    const params = this.params(paramsVar);
    rut = this.utilSV.stringReplaceClean(rut);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.clients}${rut}/executives${params}`;
    return firstValueFrom<any>(this.http.get(url, this.httpOptions));
  }

  public exportSupervisorExecutives(rut: string, paramsVar: any): Promise<any> {
    const params = this.params(paramsVar);
    const url = `${environment.MACBaseUrl}${MAC_Http_Constants.Endpoints.People.Supervisors.clients}${rut}/executives/export${params}`;
    return this.getFileCall(url);
  }

  private getFileCall(url: string) {
    return firstValueFrom<any>(this.http.get(url, {
      headers: {
        ...this.httpOptions.headers
      },
      responseType: 'blob' as 'json'
    }));
  }
}
