import { Pipe, PipeTransform } from '@angular/core';
import { clean, format, validate } from 'rut.js';

@Pipe({
  name: 'rut'
})
export class RutPipe implements PipeTransform {

  transform(rawRUT: string | undefined): string {
    if (rawRUT && validate(clean(rawRUT))) {
      return format(clean(rawRUT), { dots: true });
    } else {
      return 'Rut Invalido';
    }
  }

}
