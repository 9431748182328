<mat-dialog-actions align="end">
  <div mat-button mat-dialog-close class="close-modal">X</div>
</mat-dialog-actions>
<div mat-dialog-title>
  <span class="title-text">{{data.titleText}}</span>&nbsp;
  <span class="title-value">{{data.titleValue}}</span>
  <br>
  <span class="title-status">Estado:</span>&nbsp;
  <span class="status-value">{{statusSupervisor}}</span>
</div>

<mat-dialog-content class="mat-typography">
  <ion-grid class="tablefield-container">
    <ion-grid>
      <ion-row>
        <ion-col class="subtitle">
          Listado de Ejecutivos a cargo
        </ion-col>
        <ion-col class="btns-row">
          <button class="btn-delete-loading" *ngIf="isLoadingDownload" disabled>Descargando...</button>
          <button class="btn-export" (click)="onDownload()" [disabled]="recordsTotal===0"
            *ngIf="!isLoadingDownload">Exportar Tabla</button>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="people-filters">
      <form [formGroup]="filtersForm" (ngSubmit)="filterTable(filtersForm)">
        <ion-row class="first-row">
          <div size="auto" class="text-filter-container">
            <ion-input formControlName="nameRut" type="text" class="name-rut"
              placeholder="Buscar por nombre o RUT"></ion-input>
          </div>
          <div class="element-container">
            <select formControlName="agentRol" name="agent-rol" id="agent-rol">
              <option value="">Tipo de Ejecutivo</option>
              <option *ngFor="let rol of roles" value="{{rol.id}}">{{rol.name}}</option>
            </select>
          </div>

          <div class="element-container">
            <select formControlName="agentStatus" name="agent-status" id="agent-status">
              <option value="">Estado</option>
              <option *ngFor="let status of statuses" value="{{status.id}}">{{status.name}}</option>
            </select>
          </div>
          <div class="element-container">
            <mat-form-field appearance="outline">
              <label>Rango de Fechas</label>
              <mat-date-range-input [rangePicker]="picker">
                <input formControlName="startDate" matStartDate placeholder="Start date">
                <input formControlName="endDate" matEndDate placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <ion-col size="auto" class="btn-container d-center">
            <button type="submit" class="filter-btn">
              Filtrar
            </button>
          </ion-col>
        </ion-row>
      </form>
    </ion-grid>

    <div class="datatable-container">
      <table #dtt datatable id="peopleTableSupervisors" [dtOptions]="dtOptions" class="row-border hover"
        style="width:100%" aria-hidden="true"></table>
    </div>
  </ion-grid>

</mat-dialog-content>