import { Role, Status, VoluntaryProduct } from '@models/people.model';

export const People_Constants = {
  // public peoplePage: {},
  peopleMaintainerPage: {
    newPerson: {
      title: 'Crear Nueva Persona',
      subTitle: 'Completa los datos correspondientes para agregar una nueva persona',
      btnSave: 'Crear nueva persona'
    },
    editPerson: {
      title: 'Editar Persona',
      subTitle: 'Completa los datos correspondientes para editar persona',
      btnSave: 'Editar persona'
    }
  }
};

export const Roles: Role[] = [
  {
    id: 1,
    name: "Administrador"
  },
  {
    id: 2,
    name: "Supervisor de fidelizacion"
  },
  {
    id: 3,
    name: "Supervisor de ventas"
  },
  {
    id: 4,
    name: "Ejecutivo de fidelizacion"
  },
  {
    id: 5,
    name: "Ejecutivo de ventas"
  },
  {
    id: 6,
    name: "Operador"
  },
];

export const Statuses: Status[] = [
  {
    id: 1,
    name: "Activo"
  },
  {
    id: 2,
    name: "Eliminado"
  },
  {
    id: 3,
    name: "Vacaciones"
  },
  {
    id: 4,
    name: "Licencia"
  },
  {
    id: 5,
    name: "Afiliado"
  },
  {
    id: 6,
    name: "Desafiliado"
  },
];

export const VoluntaryProducts: VoluntaryProduct[] = [
  {
    id: '3',
    name: 'Ninguno'
  },
  {
    id: '1',
    name: 'APV'
  },
  {
    id: '2',
    name: 'CAV'
  },
  {
    id: '4',
    name: 'APV/CAV'
  },
];
